import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Shapefour from "../images/bannerElement/blog.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import "../styles/banner.css"
import "../styles/blog.css"
const Categorys = ({ data }) => {
  const eachblockcontnet = data.blogblock
  const blogbannercontent = data.blogbanner
  const catlistcontent = data.catlist
  const archiveyrcontent = data.archiveyr
  // Array of all news articles
  const catov = data.allWpCategory.nodes
  const [openFilter, setOpenFilter] = useState("")

  // State for the list
  const [list, setList] = useState([...catov.slice(0, 9)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(catov.length > 9)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < catov.length
      const nextResults = isMore
        ? catov.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < catov.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line
  return (
    <>
      {data.allWpCategory.nodes.map(({ name, slug }) => (
        <Helmet>
          <title>
            {name} | Position<sup>2</sup>
          </title>
          <body className={slug} />
        </Helmet>
      ))}

      <Layout>
        <div className="blogPage">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Insights that ignite growth</h1>
                    <p>
                      Elevate your marketing game with our AI-driven expert
                      insights
                    </p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="filter-menu">
            <div className="container">
              <div className="container-width">
                <div className="wrap">
                  <div className="filters-title">
                    <a
                      class="filter_expand filter-category"
                      onClick={() =>
                        setOpenFilter(
                          openFilter === "categories" ? "" : "categories"
                        )
                      }
                    >
                      Categories
                      <span
                        className={
                          openFilter === "categories" ? "minus" : "plus"
                        }
                      >
                        {openFilter === "categories" ? "-" : "+"}
                      </span>
                    </a>
                    <a
                      class="filter_expand filter-author"
                      onClick={() =>
                        setOpenFilter(openFilter === "authors" ? "" : "authors")
                      }
                    >
                      Author
                      <span
                        className={openFilter === "authors" ? "minus" : "plus"}
                      >
                        {openFilter === "authors" ? "-" : "+"}
                      </span>
                    </a>
                    <a
                      class="filter_expand filter-archive"
                      onClick={() =>
                        setOpenFilter(openFilter === "archive" ? "" : "archive")
                      }
                    >
                      Archive
                      <span
                        className={openFilter === "archive" ? "minus" : "plus"}
                      >
                        {openFilter === "archive" ? "-" : "+"}
                      </span>
                    </a>
                  </div>
                  <div className="filters-expands-list">
                    {openFilter === "categories" && (
                      <div className="fe-list categories-list">
                        <div className="category">
                          <ul>
                            {catlistcontent.nodes.map(({ name, uri }) => (
                              <>
                                <li>
                                  <Link to={uri}>{name}</Link>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                        <span
                          class="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                      </div>
                    )}
                    {openFilter === "authors" && (
                      <div className="fe-list authors-list">
                        <ul>
                          {eachblockcontnet.nodes.map(({ author }) => (
                            <li className={author.node.slug}>
                              <Link to={author.node.uri}>
                                {author.node.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <span
                          class="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                      </div>
                    )}
                    {openFilter === "archive" && (
                      <div className="fe-list archive-list">
                        <span
                          class="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                        <ul>
                          {archiveyrcontent.nodes.map(
                            ({ name, uri, count }) => (
                              <li>
                                <Link to={uri}>
                                  {name}
                                  <span>({count})</span>
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blog-overview">
            <div className="container">
              <div className="container-width clearfix">
                {list.map(({ posts }) => (
                  <>
                    {posts.nodes.map(
                      ({ title, blogSection, date, uri, author }) => (
                        <a href={uri} className="each-blog">
                          <div className="wrap-img">
                            <img
                              src={
                                blogSection.blogFeaturedImage.node.localFile
                                  .publicURL
                              }
                              alt={blogSection.blogFeaturedImage.node.altText}
                            />
                          </div>
                          <div className="blog-wrap">
                            <p className="date">{date}</p>
                            <h2>{title}</h2>
                            <div className="author">
                              <span>By</span> {author.node.name}
                            </div>
                            <div className="blog-descp">
                              <span>Read More</span>
                            </div>
                          </div>
                        </a>
                      )
                    )}
                  </>
                ))}
              </div>
              <div className="lm-wrap">
                {hasMore ? (
                  <button onClick={handleLoadMore}>Load More</button>
                ) : (
                  <p>No more results</p>
                )}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

Categorys.propTypes = {
  data: PropTypes.object.isRequired,
}

export const data = graphql`
  {
    allWpCategory(sort: { fields: id }) {
      nodes {
        uri
        id
        name
        slug
        posts {
          nodes {
            author {
              node {
                avatar {
                  url
                }
                name
              }
            }
            blogSection {
              blogFeaturedImage {
                node {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
            date(formatString: "ll")
            title
            uri
          }
        }
      }
    }
    archiveyr: allWpArchive(sort: { order: DESC, fields: name }) {
      nodes {
        name
        uri
        count
        slug
      }
    }
    blogbanner: allWpPage(filter: { id: { eq: "cG9zdDoxMzc2" } }) {
      edges {
        node {
          id
          title
          bannerSection {
            bannerTitle
            heroBannerImage {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
            mobileBanner {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    catlist: allWpCategory {
      nodes {
        uri
        name
      }
    }
    blogblock: allWpPost {
      nodes {
        title
        uri
        date(formatString: "LL")
        blogSection {
          blogFeaturedImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          topBannerImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
        author {
          node {
            name
            uri
            slug
            posts {
              nodes {
                title
                uri
                slug
              }
            }
          }
        }
        categories {
          nodes {
            uri
            slug
            name
            posts {
              nodes {
                slug
                title
              }
            }
          }
        }
      }
    }
  }
`

export default Categorys
